import { Gap } from "components/gap/Gap";
import { s3ImageUrl } from "utils/FileUtils";

export type TarotWelcomeProps = {};

function ComingSoonPage(props: TarotWelcomeProps) {
  return (
    <div className="flex h-screen flex-col items-center justify-center overflow-hidden bg-[#210909]">
      <div className="flex h-full w-full max-w-screen-md flex-col items-center justify-start px-4 pt-0 sm:pt-12 xl:pt-16">
        <span className="mt-6 text-center font-space text-[14px] font-normal text-brand-shape-3 sm:mt-12 sm:text-[16px] md:mt-0 md:text-[18px]">
          {`"Bạn có tin vào một trải nghiệm mà ở đó công nghệ Al sẽ giúp bạn giải mã cuộc sống hiện tại và thiết kế nên những trải nghiệm đồ uống phù hợp với bạn nhất?"`}
        </span>
        <div
          className="relative mt-40 w-full sm:mt-64 md:mt-64"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            style={{
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="absolute left-0 right-0 z-10 w-[180px] max-w-[400px] md:w-[330px] xl:w-[330px]"
            src={s3ImageUrl("tarot_girl_new.webp")}
            alt=""
          />
          <img
            style={{
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="absolute left-0 right-0 w-[160px] max-w-[400px] sm:w-[330px] xl:w-[400px]"
            src={s3ImageUrl("san_logo_bg.png")}
            alt=""
          />
          <img
            className="absolute -right-36 z-30 h-20"
            src={s3ImageUrl("red_cloud.png")}
            alt=""
          />
          <img
            className="absolute right-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -left-36 bottom-10 z-30 h-20"
            src={s3ImageUrl("red_cloud.png")}
            alt=""
          />
          <img
            className="absolute bottom-32 left-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -left-20 bottom-0 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -bottom-32 left-20 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -left-10 -top-72 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -right-10 -top-72 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -right-10 -top-72 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -right-10 -top-32 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -bottom-32 -right-32 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
        </div>
        {/* Mobile */}
        <span className="mt-36 whitespace-pre text-center font-space text-[14px] font-normal text-brand-shape-3 sm:mt-48 sm:hidden sm:text-[18px] md:mt-60 md:text-[18px]">
          {`Hãy sẵn sàng để khám phá những điều\n bất ngờ sắp ra mắt trên website của chúng mình. \nHiện tại, các bạn hãy đến quán chúng mình \nđể trải nghiệm trực tiếp và nhận vô vàn \nưu đãi hấp dẫn nhé!`}
        </span>
        <span className="mt-36 hidden whitespace-pre text-center font-space text-[16px] font-normal text-brand-shape-3 sm:mt-48 sm:text-[18px] md:mt-60 sm:block md:hidden md:text-[18px] lg:hidden">
          {`Hãy sẵn sàng để khám phá những điều bất ngờ sắp ra mắt trên website \ncủa chúng mình. Hiện tại, các bạn hãy đến quán chúng mình để trải nghiệm trực tiếp\n và nhận vô vàn ưu đãi hấp dẫn nhé!`}
        </span>
        {/* Tablet */}
        <span className="mt-36 hidden whitespace-pre text-center font-space text-[16px] font-normal text-brand-shape-3 sm:mt-48 sm:text-[18px] md:mt-60 md:block md:text-[18px] lg:hidden">
          {`Hãy sẵn sàng để khám phá những điều bất ngờ sắp ra mắt trên website \ncủa chúng mình. Hiện tại, các bạn hãy đến quán chúng mình để trải nghiệm trực tiếp\n và nhận vô vàn ưu đãi hấp dẫn nhé!`}
        </span>
        <span className="mt-36 hidden whitespace-pre text-center font-space text-[16px] font-normal text-brand-shape-3 sm:mt-48 sm:text-[18px] md:mt-60 md:text-[18px] lg:block">
          {`Hãy sẵn sàng để khám phá những điều bất ngờ sắp ra mắt trên website của chúng mình. \nHiện tại, các bạn hãy đến quán chúng mình để trải nghiệm trực tiếp và nhận vô vàn ưu đãi hấp dẫn nhé!`}
        </span>
        <div className="mt-2 flex flex-col items-center justify-center gap-4 sm:mt-4 sm:flex-row">
          <div
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                "https://maps.app.goo.gl/D1NdJe1RyqQwRuCP6",
                "_blank",
              );
            }}
            className="mt-4 flex cursor-pointer flex-row items-center justify-center gap-3 sm:mt-0 sm:flex-row sm:items-start"
          >
            <img
              src={s3ImageUrl("icon_place.png")}
              className="mt-1 w-11 sm:mt-0 sm:w-12"
              alt=""
            />
            <span className="whitespace-pre text-center font-space text-[14px] font-normal text-white sm:text-right sm:text-[16px]">
              {`Căn SH 36-37 Khu nhà ở Rue de Charme, \nNgõ 214 Nguyễn Xiển, KĐT mới Hạ Đình, Hà Nội.`}
            </span>
          </div>
          <div className="flex flex-row items-start gap-2 sm:gap-1">
            <img
              src={s3ImageUrl("san_logo_brand_3.png")}
              className="w-10 sm:w-12"
              alt=""
            />
            <div className="ml-1 h-10 w-[2px] bg-white sm:h-12" />
            <div className="-mt-1 flex flex-col gap-1">
              <div
                className="flex cursor-pointer flex-row items-center gap-1"
                onClick={() => {
                  window.open("https://san.cafe/");
                }}
              >
                <img
                  src={s3ImageUrl("icon_web.png")}
                  className="h-4 w-4 sm:h-6 sm:w-6"
                  alt=""
                />
                <span className="whitespace-pre text-right font-space text-[14px] font-normal text-white sm:text-[16px]">
                  {`san.cafe`}
                </span>
              </div>
              <div
                className="flex cursor-pointer flex-row items-center gap-1"
                onClick={() => {
                  window.open("tel:1900 8643");
                }}
              >
                <img
                  src={s3ImageUrl("icon_phone.png")}
                  className="mb-1 h-4 w-4 sm:h-6 sm:w-6"
                  alt=""
                />
                <span className="whitespace-pre text-right font-space text-[14px] font-normal text-white sm:text-[16px]">
                  {`1900 8643`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonPage;
